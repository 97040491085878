<template>
  <div class="min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa allekirjoitettavaa dokumenttia</span>
    </div>

    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Ladataan sopimusta..."
      class="full-page-loader"
    ></full-page-loader>

    <v-stepper v-if="!loading" class="mt-2" v-model="step">
      <v-stepper-header>
        <v-stepper-step
          step="1"
          :rules="[() => (step > 1 ? validateData() : true)]"
          :complete="dataCheck"
          >Tiedot</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step step="2" :rules="[() => (step > 2 ? validateInformation() : true)]"
          >Sopimus</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <!-- Document -->
          <document-form ref="dataForm"></document-form>

          <div align="right">
            <v-btn color="primary" @click="step = 2">Seuraava</v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="buttons-wrapper">
            <v-btn color="error" outlined @click="step = 1">Takaisin</v-btn>
          </div>

          <!-- ERROR -->
          <h3 v-if="!dataCheck" class="mt-2 error--text">
            Lisää vähintään sopimuksen nimi ja yksi vuokralainen sopimuksen osapuoliin!
          </h3>

          <!-- Summary -->
          <Summary :isValidContract="dataCheck" ref="summary"></Summary>

          <div class="buttons-wrapper">
            <v-btn color="error" outlined @click="step = 1">Takaisin</v-btn>
            <v-btn color="info" @click.prevent="submit()">Tallenna</v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import DocumentForm from "../../components/OtherContracts/DocumentForm";
import Summary from "../../components/OtherContracts/Summary";
import mixins from "../../mixins/mixins";
import FullPageLoader from "@/components/FullPageLoader";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  title: "Luo vuokrasopimus",

  components: {
    DocumentForm,
    Summary,
    FullPageLoader,
  },

  mixins: [mixins],

  data() {
    return {
      step: 1,
      dataCheck: false,
    };
  },

  created() {
    this.setLoading(true);

    this.getContract(this.$route.params.id);

    eventBus.$on("validatedata", (boolean) => {
      this.dataCheck = boolean;
    });
  },

  computed: {
    ...mapState("othercontract", ["contract", "loading"]),
    ...mapState("contractmodel", ["contentError"]),
  },

  watch: {
    step(val) {
      if (val == 2) {
        this.$refs.summary.getAllContent();
      }
    },

    contract(val) {
      // Initial values to arrays
      this.$store.state.tenant.activeTenants = [...val.otherTenants];
      if (val.tenant) this.$store.state.tenant.activeTenants.push({ ...val.tenant });
      if (val.apartment) this.$store.state.apartment.activeApartments.push({ ...val.apartment });

      this.$store.state.keys.activeKeys = [...val.keys];
      const landlords = [];
      val.landlords.forEach((el) => {
        const landlord = { ...el.landlordId, landlordId: el._id, onModel: el.onModel };
        landlords.push({ ...landlord });
      });
      // Set contract landlords correctly
      this.$store.state.othercontract.contract.landlords = [...landlords];
      // set active arrays
      this.$store.state.account.activeLandlords = [...landlords];

      this.setLoading(false);
    },
  },

  methods: {
    ...mapActions("othercontract", ["getContract", "updateOtherContract"]),
    ...mapMutations("othercontract", ["setLoading"]),

    validateData() {
      this.$refs.dataForm.validateForm();

      if (this.dataCheck) return true;
      else return false;
    },

    async submit() {
      // Check if all content is loaded to summary
      if (
        this.$refs.summary.modelContentLoaded == "" ||
        this.$refs.summary.modelContentLoaded == "<p></p>" ||
        this.contentError
      ) {
        return this.showPopup(
          'Lataa vuokrasopimuksen tiedot sopimuspohjan "Lataa"-napista tai luo uusi sopimuspohja ja varmista, että siinä on sisältöä ja se on oikein muotoiltu.',
          "error"
        );
      }

      if (this.dataCheck) {
        const contractData = this.$store.getters["othercontract/getContract"];

        try {
          await this.updateOtherContract({ contract: contractData, id: this.$route.params.id });
          this.$router.push("/other-contracts");
        } catch (err) {
          this.showPopup(err, "error");
        }
      } else {
        this.showPopup(
          "Lomakkeen kaikki kentät eivät ole täytetty oikein. Tarkista kaikki pakolliset kentät.",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>
